/* eslint-disable*/
/* -------------------------------------------------------------------------- */
/*                                Universal contact form ajax submission                                  */
/* -------------------------------------------------------------------------- */
const formInit = () => {
  const zforms = document.querySelectorAll("[data-form]");

  if (zforms.length) {
    zforms.forEach((form) => {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const feedbackEl = form.querySelector('.feedback');
        const formData = {};
        Array.from(form.elements).forEach(el => {
          if (el.type !== 'submit') {
            formData[el.name] = el.value
          }
        })
      
        window.Email.send({
          Host: "smtp.gmail.com",
          Username: "c.weiss@desire2grow.com",
          Password: "n^%Bd5c7d76etnd!",
          To: "c.weiss@desire2grow.com",
          From: formData.email,
          Subject: "Neue Nachricht von Desire2Grow.com",
          Body: `
            <p>Neue Nachricht von der Desire2Grow Website:<p>
            <p>Name: ${formData.name}</p>
            <p>email: ${formData.email}</p>
            <p>Nachricht:<br />${formData.message}</p>
          `
        }).then(
          message => {
            feedbackEl.innerHTML = `<div class="alert alert-success alert-dismissible" role="alert">
            <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
            Your message has been sent successfully.<br />
            ${formData.name}
            ${formData.email}
            ${formData.message}
            I will get back to you soon.
          </div>`
          }
        ).catch(error => {
          feedbackEl.innerHTML = `<div class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
          Your message was not sent. Please send an email to c.weiss@desire2grow.com
        </div>`
        })
      })
    });
  }
};

export default formInit;